    <template>
    <v-main>
        <v-container fluid style="max-width: 1400px">
            <div class="text-h4 mb-2">Roster Dashboard</div>
            <v-divider class="mb-4" />
            <div v-if="config.loadingAvailable">
                <div class="text-center text--secondary text-h5 font-weight-light mb-2">Loading available rosters...</div>
                <v-progress-linear
                    color="primary"
                    indeterminate
                />
            </div>
            <div v-else-if="availableRosters.length">
                <v-select
                    v-model="config.selectedRoster"
                    :items="availableRosters"
                    label="Selected Roster"
                    item-text="Title"
                    return-object
                    outlined
				/>
                <v-row>
                    <v-col cols="12">
                        <RosterAction
                            url="/finance/rosters/fix_roster_permissions/"
                            button-text="Fix Permissions"
                            button-text-running="Fixing Permissions..."
                            button-icon="mdi-wrench"
                            :title="`Fix permissions on ${config.selectedRoster.Title}?`"
                            :roster="config.selectedRoster"
                        />
                        
                    </v-col>
                    <v-col cols="12">
                        <RosterAction
                            url="/finance/rosters/apply_roster_calculations/"
                            button-text="Run Calculations"
                            button-text-running="Running Calculations..."
                            button-icon="mdi-calculator"
                            confirm-button-text="Apply Calculations"
                            :title="`Run calculations on ${config.selectedRoster.Title}?`"
                            :roster="config.selectedRoster"
                            :calculation="config.selectedCalculation"
                        >
                            <template v-slot:dialog>
                                <v-card-text class="mb-0 pb-0">
                                    <v-select
                                        v-model="config.selectedCalculation"
                                        :items="availableCalculations"
                                        label="Select Calculation"
                                        item-text="name"
                                        return-object
                                        outlined
                                        hide-details
                                    />
                                    <v-sheet class="pa-1 mt-2" outlined>
                                        <pre v-text="config.selectedCalculation.calculation" />
                                    </v-sheet>
                                </v-card-text>
                            </template>
                            <template v-slot:middleButton="{ runExport }">
                                <v-btn
                                    color="blue darken-1"
                                    @click.stop="runExport('/finance/rosters/export_roster_calculations/')"
                                    text
                                >
                                    Export Excel
                                </v-btn>
                            </template>
                        </RosterAction>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <div class="text-center text--secondary text-h5 font-weight-light">No available rosters</div>
            </div>
        </v-container>
        
    </v-main>
</template>
<script>
import axios from 'axios';
import { ref, reactive, onMounted } from '@vue/composition-api';
import RosterAction from '@/components/finance/RosterAction';

export default {
    components: {
        RosterAction
    },
    setup() {

        const config = reactive({
            loadingAvailable: false,
            selectedRoster: null,
            selectedCalculation: null,
        });

        const availableRosters = ref([]);
        const availableCalculations = ref([]);

        const loadAvailableRosters = async () => {
            config.loadingAvailable = true;

            const response = await axios.get('/finance/rosters/available/');
            availableRosters.value = response.data;
            config.selectedRoster = availableRosters.value[response.data.length - 1];

            // also load calculations now
            const calcResponse = await axios.get('/finance/rosters/calculations/');
            availableCalculations.value = calcResponse.data;
            config.selectedCalculation = availableCalculations.value[calcResponse.data.length - 1];
            
            config.loadingAvailable = false;
        };

        onMounted(() => {
            loadAvailableRosters();
        });

        return {
            config,
            availableRosters,
            availableCalculations,
        }
    }
}
</script>