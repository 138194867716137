<template>
    <div>
        <ConfirmDialog
            :title="title"
            button-color="primary"
            :button-text="!config.running ? buttonText : buttonTextRunning"
            :button-icon="buttonIcon"
            button-xlarge
            button-outlined
            button-block
            :button-disabled="config.running"
            :confirm-button-text="confirmButtonText || buttonText"
            confirm-button-color="primary"
            :confirm-button-disabled="buttonText === 'Run Calculations' && !calculation"
            @confirm="run"
            @export="runExport"
            ref="dialog"
        >
            <template v-slot:dialog>
                <slot name="dialog" />
            </template>
            <template v-slot:middleButton>
                <slot name="middleButton" :runExport="runExport" />
            </template>
        </ConfirmDialog>
        <div
            v-if="config.current"
            class="text--secondary caption"
        >
            Processed <strong v-text="config.current" /> of <strong v-text="config.total" />
            <v-progress-linear
                :value="config.progress"
                color="primary"
                rounded
            />
        </div>
    </div>         
</template>
<script>
import axios from 'axios';
import { reactive, onMounted } from '@vue/composition-api';
import ConfirmDialog from '@/components/common/ConfirmDialog';

export default {
    components: {
        ConfirmDialog,
    },
    props: {
        title: {
            type: String
        },
        buttonText: {
            type: String,
        },
        buttonTextRunning: {
            type: String,
        },
        buttonIcon: {
            type: String
        },
        confirmButtonText: {
            type: String
        },
        url: {
            type: String,
        },
        roster: {
            type: Object,
            default() {
                return {}
            }
        },
        calculation: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    setup(props, { refs }) {

        const config = reactive({
            running: false,
            interval: null,
            current: null,
            total: null,
            progress: 0,
        });

        const runExport = async (url) => {
            refs.dialog.close();
            run(null, url);
        };

        const run = async (event, altUrl = null) => {
            config.running = true;

            const data = {
                roster: props.roster,
                calculation: props.calculation,
            };

            const response = await axios.post(altUrl || props.url, data);
            poll(response.data.task_id)
        }

        const stop = () => {
            clearInterval(config.interval);
            config.interval = null;
            config.running = false;
            config.current = null;
            config.total = null;
        };

        const isRunning = async () => {
            const response = await axios.get(props.url);
            if (response.data.running) {
                config.running = true;
                poll(response.data.running);
            }
        };

        const exportFile = async (payload) => {
            const link = document.createElement('a');
            const fileName = payload.name;
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            link.href = `data:${fileType};base64,${payload.file}`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        const poll = async (taskId) => {
            config.interval = setInterval(async () => {
                const response = await axios.get(`${props.url}?task=${taskId}`);

                if (!response.data.task_info) return;

                switch (response.data.task_status) {
                    case 'PENDING':
                    case 'PROGRESS': {
                        config.current = response.data.task_info.current;
                        config.total = response.data.task_info.total;
                        config.progress = response.data.task_info.current / response.data.task_info.total * 100;
                        break;
                    }
                    case 'SUCCESS': {
                        if (response.data.file_data && config.running) {
                            exportFile({
                                file: response.data.file_data,
                                name: response.data.file_name,
                            });
                        }
                        stop();
                        break;
                    }
                    case 'FAILURE': {
                        stop();
                        break;
                    }
                    default:
                        stop();
                }
            }, 1000);
        };

        onMounted(() => {
            isRunning();
        });

        return {
            config,
            run,
            runExport,
        }
    }
}
</script>